import React from 'react'
import footerbg from '../../Assets/footer.png'
import { Link } from 'react-router-dom'
function Footer() {
  return (
    <div className='relative'>
      <img className='h-screen sm:h-full object-cover' src={footerbg} alt="footerbg" />

      <div className='absolute top-0 left-0 right-0 bottom-0 flex sm:flex-row flex-col justify-center items-center gap-12'>
        <div className='text-[24px] xl:text-[30px] sm:text-[20px] md:text-[26px] text-white font-bold gap-2 flex flex-col justify-center sm:items-start items-center'>
          <p>O Nama</p>
          <div className='w-[45px] h-[5px] rounded-3xl bg-[#F94A23]'/>
          <p className='w-[250px] text-[16px] font-medium sm:text-start text-center'>Bavimo se pre svega uredjivanjem okoline i hortikulturom...<Link className='underline' to='/o-nama'>Pogledaj Više</Link></p>
        </div>

        <div className='text-[24px] xl:text-[30px] sm:text-[20px] md:text-[26px] text-white font-bold gap-2 flex flex-col justify-center sm:items-start items-center'>
          <p>Kontaktirajte Nas</p>
          <div className='w-[45px] h-[5px] rounded-3xl bg-[#F94A23]'/>
          <p className='w-[250px] text-[16px] font-medium sm:text-start text-center'>• 062 859 8283 <br/> • timnngarden@hotmail.com <br/> • Ćuprija Nikole Tesle broj 29 </p>
        </div>


        <div className='text-[24px] xl:text-[30px] sm:text-[20px] md:text-[26px] text-white font-bold gap-2 flex flex-col justify-center sm:items-start items-center'>
          <p>Društvene Mreže</p>
          <div className='w-[45px] h-[5px] rounded-3xl bg-[#F94A23]'/>
          <p className='w-[250px] text-[16px] font-medium sm:text-start text-center'>• <a href="https://www.instagram.com/kosenje_i_obaranje_stabala_035/">Instagram</a>
           <br/> • <a href="https://www.facebook.com/AutmobiliTraktorimotori?locale=sr_RS">Facebook</a>
            <br/>•  <a href="https://wa.me/+381628598283">Whatsapp</a> <br/></p>
        </div>

        <div className='text-[24px] xl:text-[30px] sm:text-[20px] md:text-[26px] text-white font-bold gap-2 flex flex-col justify-center sm:items-start items-center'>
          <p>Istraži</p>
          <div className='w-[45px] h-[5px] rounded-3xl bg-[#F94A23]'/>
          <a href='https://github.com/KowyXYZ' className='w-[250px] text-[16px] font-medium sm:text-start text-center'>• UI/ UX Dizajn<br/> • Izrada Web Aplikacija <br/> • Web Konsultacije</a>
        </div>

        
      </div>

      <div className='bg-[#333] text-[#fff] justify-center items-center flex py-1'>
        <p>	&#169; Sva prava zadržana 2023 TIM NN</p>
      </div>
      
    </div>
  )
}

export default Footer