import React, { useState } from 'react'
import { Link} from 'react-router-dom'
import logo from '../../Assets/logotipful.png'
import close from '../../Assets/close.svg'
import menu from '../../Assets/menu.svg'
function Navbar() {

  const [toggle, setToggle] = useState(false)

  return (
    <div className='w-full bg-[#0F4229] z-30 uppercase'>
      <div className='sm:flex hidden container mx-auto justify-center 2xl:gap-80 xl:gap-64 md:gap-32 sm:gap-12 items-center py-5 xl:text-[18px]  text-[#fff] z-30'>
            

            <div  className='flex gap-8'>
              <Link className='border-b-2 border-transparent hover:border-[#FA4219] transition-all' to='/'>Početna</Link>
              <Link className='border-b-2 border-transparent hover:border-[#FA4219] transition-all' to='/o-nama'>O Nama</Link>
            </div>

            <Link to='/'>
              <img className='xl:w-36 md:w-24 sm:w-20' src={logo} alt="logo" />
            </Link>

            <div className='flex gap-8'>
              
            
              <Link to='/kontakt' className='border-b-2 border-transparent hover:border-[#FA4219] transition-all'>Kontakt</Link>
              <a href='#usluge' className='border-b-2 border-transparent hover:border-[#FA4219] transition-all'>Naše Usluge</a>
            </div>
        
          </div>

          <div className='flex sm:hidden container mx-auto justify-around  items-center py-5 text-[18px] text-[#fff] z-30'>
            
             <Link to='/'>
              <img className='w-36' src={logo} alt="logo" />
            </Link>

              <img className='w-6' src={toggle ? close : menu} alt="hammenu"  onClick={() => setToggle(!toggle)}/>
              <div className={`${toggle ? 'flex' : 'hidden'} border-2 absolute rounded-2xl flex flex-col text-[22px] top-20 right-5 z-50 bg-[#0F4229] text-[#fff]  `}>
             

                  <div className='flex flex-col p-4 gap-4 text-center'>
                    <Link className='border-b-2 border-transparent hover:border-[#FA4219] transition-all' to='/' onClick={() => setToggle(false)}>Pocetna</Link>
                    <Link className='border-b-2 border-transparent hover:border-[#FA4219] transition-all' onClick={() => setToggle(false)} to='/o-nama'>O Nama</Link>
                  
                    <Link className='border-b-2 border-transparent hover:border-[#FA4219] transition-all' onClick={() => setToggle(false)} to='/kontakt'>Kontakt</Link>

              
                  </div>
           
              </div>
          </div>
    </div>
   
  )
}

export default Navbar