import React from 'react'
import bg from '../../Assets/trava.jpg'
import logo from '../../Assets/logotipful.png'

import phone from '../../Assets/phone.png'
import location from '../../Assets/location.png'
import mail from '../../Assets/mail.png'

import fb from '../../Assets/fb.png'
import ig from '../../Assets/ig.png'
import wa from '../../Assets/wa.png'

function ContactUs() {
  return (
    <div className=' justify-center items-center flex relative '>
        <img className='w-full h-screen object-cover  ' src={bg} alt="blackbg" />

        <div className='absolute  top-0 right-0 left-0 bottom-0 sm:flex hidden flex-row sm:justify-between px-44 items-center gap-4  bg-[rgba(0,0,0,0.70)] '>
            <div className='font-bold'>
              <p className='text-[#fff]'>Pricajte sa nama</p>
              <p className='text-[#F94A23] text-[44px]'>Kontakt</p>
              <div className='flex flex-col gap-5 text-[24px]' >
                <div className='flex justify-start items-center gap-4 text-[#fff] text-start font-bold'>
                    <div>
                        <img className='w-8' src={phone} alt="telefon" />
                    </div>

                    <div>
                      <p>Kontakt Tel.</p>
                      <p className='font-medium'>062 859 8283</p>
                    </div>
                  
                </div>


                <div className='flex justify-start items-center  gap-4 text-[#fff] text-start'>
                    <div>
                        <img className='w-8' src={mail} alt="mail" />
                    </div>
                    
                    <div>
                      <p>Email</p>
                      <p className='font-medium'>timnngarden@hotmail.com</p>
                    </div>
                  
                </div>

                <div className='flex justify-start items-center  gap-4 text-[#fff] text-start'>
                    <div>
                        <img className='w-8' src={location} alt="location" />
                    </div>
                    
                    <div>
                      <p>Lokacija</p>
                      <p className='font-medium'>Nikole Tesle broj 29</p>
                    </div>
                  
                </div>

                <div className='flex flex-row gap-8'> 
                    <a  href="https://www.facebook.com/AutmobiliTraktorimotori?locale=sr_RS"><img src={fb} alt="fb" /></a>
                    <a  href="https://www.instagram.com/kosenje_i_obaranje_stabala_035/"><img src={ig} alt="ig" /></a>
                    <a href="https://wa.me/+381628598283"><img src={wa} alt="wa" /></a>
                </div>
             

              </div>
            </div>

            <div className='flex justify-center items-center flex-col'>
              <img className='w-80' src={logo} alt="logos" />
              <div className='bg-[#0F4229]   rounded-3xl p-4 mt-4'>
                    <form className='flex justify-center items-center flex-col gap-4'>
                        <div className='flex items-center flex-col justify-center gap-2 text-[#fff]'>
                          <label className='font-bold'>Ime:</label> 
                          <input className='p-2 rounded-xl text-[#000] outline-none w-[400px]' type="text" placeholder='Ime' />
                        </div>

                        <div className='flex items-center flex-col justify-center gap-2 text-[#fff]'>
                          <label className='font-bold'>Prezime:</label> 
                          <input className='p-2 rounded-xl text-[#000] outline-none w-[400px]' type="text" placeholder='Prezime' />
                        </div>

                        <div className='flex items-center flex-col justify-center gap-2 text-[#fff]'>
                          <label className='font-bold'>Email:</label> 
                          <input className='p-2 rounded-xl text-[#000] outline-none w-[400px]' type="email" placeholder='Email' />
                        </div>

                        
                        <div className='flex items-center flex-col justify-center gap-2 text-[#fff]'>
                          <label className='font-bold'>Poruka:</label> 
                          <textarea placeholder='Poruka' className='w-[400px] rounded-xl outline-none p-2 text-[#000]' ncols="30" rows="5" style={{resize: 'none'}}></textarea>
                        </div>
                    </form>
                   </div>
            </div>
            
        </div>

        <div className='absolute top-0 right-0 left-0 bottom-0 justify-center items-center gap-6 flex flex-col bg-[rgba(0,0,0,0.65)] sm:hidden'>

       


               <div className='flex justify-center items-center gap-4 text-[#fff] text-center font-bold'>
                  

                    <div>
                      <p>Kontakt Tel.</p>
                      <p className='font-medium'>062 859 8283</p>
                    </div>
                </div>

                
                <div className='flex justify-center items-center  gap-4 text-[#fff] text-center font-bold'>
                
                    <div>
                      <p>Email</p>
                      <p className='font-medium'>timnngarden@hotmail.com</p>
                    </div>
                  
                </div>

                <div className='flex justify-center items-center  gap-4 text-[#fff] text-center font-bold'>
                  
                    
                    <div>
                      <p>Lokacija</p>
                      <p className='font-medium'>Nikole Tesle broj 29</p>
                    </div>
                  
                </div>

                <div className='flex justify-center items-center flex-col'>
                <p className='text-[#F94A23] sm:text-[44px] text-[24px]'>Kontakt</p>
              <div className='bg-[#0F4229]  rounded-3xl p-4 mt-4'>

                    <form className='flex justify-center items-center flex-col gap-4'>
                        <div className='flex items-center flex-col justify-center gap-2 text-[#fff]'>
                          <label className='font-bold'>Ime:</label> 
                          <input className='p-2 rounded-xl text-[#000] outline-none w-[300px]' type="text" placeholder='Ime' />
                        </div>

                        <div className='flex items-center flex-col justify-center gap-2 text-[#fff]'>
                          <label className='font-bold'>Prezime:</label> 
                          <input className='p-2 rounded-xl text-[#000] outline-none w-[300px]' type="text" placeholder='Prezime' />
                        </div>

                        <div className='flex items-center flex-col justify-center gap-2 text-[#fff]'>
                          <label className='font-bold'>Email:</label> 
                          <input className='p-2 rounded-xl text-[#000] outline-none w-[300px]' type="email" placeholder='Email' />
                        </div>

                        
                        <div className='flex items-center flex-col justify-center gap-2 text-[#fff]'>
                          <label className='font-bold'>Poruka:</label> 
                          <textarea placeholder='Poruka'  className='w-[300px] rounded-xl outline-none p-2 text-[#000] ' ncols="30" rows="2" style={{resize: 'none'}}></textarea>
                        </div>
                    </form>
                   </div>
            </div>
            
        </div>
    </div>
  )
}

export default ContactUs