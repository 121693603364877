import {Route, RouterProvider, createBrowserRouter, createRoutesFromElements} from 'react-router-dom'
import RootLayout from './Layouts/RootLayout'
import Home from './Pages/Home';
import AboutMain from './Components/About/AboutMain';
import KontaktMain from './Components/Contact/KontaktMain';
function App() {

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path='/' element={<RootLayout/>}>
        <Route index element={<Home/>}/>
        <Route path='/o-nama' element={<AboutMain/>}/>
        <Route path='/kontakt' element={<KontaktMain/>}/>
      </Route>
    )
  )



  return (
    <div>
      <RouterProvider router={router}/>
    </div>
  );
}

export default App;
