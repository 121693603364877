import React from 'react'
import abou from '../../Assets/logotip.jpg'
import { Link } from 'react-router-dom'
function About() {
  return (
    <div className='container mx-auto flex sm:flex-row flex-col justify-between text-center sm:text-start items-center py-24'>
        <div>
            <p className='font-black text-[18px] uppercase text-[#333333]'>Ukratko O Nama</p>
            <p className='text-[#F94A23] sm:text-[32px] text-[24px] font-bold'>Dobrodošli vaš NN Tim</p>
            <p className='w-[300px] sm:w-[400px] md:w-[500px] xl:w-[800px] xl:text-[22px] sm:text-[16px] md:text-[20px] text-[20px]'>Naša firma Tim NN Ćuprija registrovana je 2021. godine sa sedištem u Ćupriji, u ulici Nikole Tesle broj 29.Bavimo se pre svega uredjivanjem okoline i hortikulturom.U radu nas krasi kreativnost i inovativnost, kao i pedantnost.</p>
            <div className='mt-4'>
                <Link to='/o-nama' className='bg-[#F94A23]  py-2 px-8 text-white rounded-2xl '>Pogledaj Više</Link>
            </div>
        </div>


        <div>
            <img className='xl:w-80 md:w-44 sm:w-32 w-44 mt-12 sm:mt-0' src={abou} alt="aboutimg" />
        </div>
    </div>
  )
}

export default About