import React from 'react'

import { Swiper, SwiperSlide} from 'swiper/react';
import { Navigation, Pagination, Autoplay} from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import image1 from '../../Assets/Galerija/slika1.webp'
import image2 from '../../Assets/Galerija/slika2.webp'
import image3 from '../../Assets/Galerija/slika3.webp'
import image4 from '../../Assets/Galerija/slika4.webp'
import image5 from '../../Assets/Galerija/slika5.webp'
import image6 from '../../Assets/Galerija/slika6.webp'
import image7 from '../../Assets/Galerija/slika7.webp'
import image8 from '../../Assets/Galerija/slika8.webp'
import image9 from '../../Assets/Galerija/slika9.webp'
import image10 from '../../Assets/Galerija/slika10.webp'
import image11 from '../../Assets/Galerija/slika11.webp'


function Galerija() {
  return (
    <div className='container mx-auto flex justify-center items-center flex-col py-24'>
        <p className='text-[#333333] text-[20px] font-black'>Naši Radovi</p>
        <p className='text-[#FA4219] text-[44px] font-black'>Galerija</p>
        <div>
        <Swiper
            spaceBetween={20}
            slidesPerView={1}
            className='w-[50vh] sm:w-[99vh] rounded-2xl'
            pagination={{ clickable: true }}
            modules={[Autoplay, Navigation, Pagination]}
            navigation={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            centeredSlides

        >
            <SwiperSlide className='rounded-2xl'><img className='sm:w-screen  h-[500px] object-cover rounded-2xl' src={image1} alt="slika1" /></SwiperSlide>
            <SwiperSlide className='rounded-2xl'><img className='sm:w-screen h-[500px] object-cover rounded-2xl' src={image2} alt="slika1" /></SwiperSlide>
            <SwiperSlide className='rounded-2xl'><img className='sm:w-screen  h-[500px] object-cover rounded-2xl' src={image3} alt="slika1" /></SwiperSlide>
            <SwiperSlide className='rounded-2xl'><img className='sm:w-screen h-[500px] object-cover rounded-2xl' src={image4} alt="slika1" /></SwiperSlide>
            <SwiperSlide className='rounded-2xl'><img className='sm:w-screen h-[500px] object-cover rounded-2xl' src={image5} alt="slika1" /></SwiperSlide>
            <SwiperSlide className='rounded-2xl'><img className='sm:w-screen h-[500px] object-cover rounded-2xl' src={image6} alt="slika1" /></SwiperSlide>
            <SwiperSlide className='rounded-2xl'><img className='sm:w-screen h-[500px] object-cover rounded-2xl' src={image7} alt="slika1" /></SwiperSlide>
            <SwiperSlide className='rounded-2xl'><img className='sm:w-screen h-[500px] object-cover rounded-2xl' src={image8} alt="slika1" /></SwiperSlide>
            <SwiperSlide className='rounded-2xl'><img className='sm:w-screen h-[500px] object-cover rounded-2xl' src={image9} alt="slika1" /></SwiperSlide>
            <SwiperSlide className='rounded-2xl'><img className='sm:w-screen  h-[500px] object-cover rounded-2xl' src={image10} alt="slika1" /></SwiperSlide>
            <SwiperSlide className='rounded-2xl'><img className='sm:w-screen h-[500px] object-cover rounded-2xl' src={image11} alt="slika1" /></SwiperSlide>

          </Swiper>
        </div>
    </div>
  )
}

export default Galerija