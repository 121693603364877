import React from 'react'
import heroimg from '../../Assets/unsplash1.png'
import { Link } from 'react-router-dom'
function HeroSection() {
  return (
    <div className='relative'>
        <div className=' top-0 right-0 left-0 bottom-0 '>
            <img className='  w-full h-screen object-cover' src={heroimg} alt="heroimg" />
        </div>

        <div className='absolute flex-col top-0 right-0 left-0 bottom-0 flex justify-center items-center gap-4 bg-[rgba(0,0,0,0.65)] '>
            <p className='text-[28px] sm:text-[44px] text-center w-[300px] sm:w-[400px] md:w-[600px] xl:w-[800px] uppercase font-bold text-[#fff] '>Transformišite svoj prostor i oživite svoje okruženje uz naše inovativne ideje za uređenje</p>
            <p className='text-[16px] text-center text-[gray] w-[300px] sm:w-[300px] md:w-[500px] xl:w-[700px]'>"Učinite svaki trenutak posebnim uređenjem vašeg okruženja. Otkrijte naše elegantne i praktične ideje za uređenje prostora koje će unaprediti vaš životni stil."</p>
            <div className='flex gap-4 justify-center flex-col sm:flex-row items-center mt-4 text-[#fff]'>
                <Link to='/o-nama' className='bg-[#0F4229] py-2 px-8 rounded-3xl text-[20px]'>Vise...</Link>
                <a href='#kontakt' className='bg-[#FA4219] py-2 px-8 rounded-3xl text-[20px]'>Kontakt</a>
            </div>
        </div>
    </div>
  )
}

export default HeroSection