import React from 'react'
import logo from '../../Assets/logotipful.png'
import bg from '../../Assets/trava.jpg'
function AboutMain() {
  return (
    <div  className={`flex justify-center flex-col items-center  text-[16px] sm:text-[20px] text-center py-12  bg-hero-pattern bg-cover bg-no-repeat bg-center `}>
        <p className='w-[300px] sm:w-[600px] bg-[#fff] p-5 rounded-2xl flex justify-center items-center flex-col '>Bavimo se kompletnim i detaljnim uredivanjem dvorista.
Pod našim uslugama uredjivanja dvorista nudimo kompletno pripremanje terena za travnjake i bašte, ulepšavanje
dvorišta ili kompletno tretiranje parkova i drugih javnih površina.
Bilo da se radi o parkovima i vrtovima ili pak privatnim zelenim dvorištima, mi pružamo i usluge košenja trave,
oblikovanja žive ograde, uklanjanja korova i sl. Kompletne sluge uredjenja dvorišta možemo urediti po vasoj želji i
zamisli, a ako nam date prostora, oduševićemo Vas svojim idejama i kreativnošću.
Naš tim čine judi sa iskustvom koji se svom poslu posvećuju do maksimuma, a krasi ih kreativnost i
profesionalizam.
Za slučaj da Vam je potrebno više dodatnih informacija ili neka od nasih usluga, pozovite na navedeni broj telefona
ili nam pišite na navedenu imejl adresu ili nas posetite u Ćupriji.<img className='w-80 mt-4' src={logo} alt="logo" /></p>


    </div>
  )
}

export default AboutMain