import React from 'react'
import ContactUs from '../../Components/Home/ContactUs'
function KontaktMain() {
  return (
    <div>
        <ContactUs/>
    </div>
  )
}

export default KontaktMain