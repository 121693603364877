import React from 'react'
import HeroSection from '../Components/Home/HeroSection'
import About from '../Components/Home/About'
import Usluge from '../Components/Home/Usluge'
import Galerija from '../Components/Home/Galerija'

function Home() {
  return (
    <div>
        <HeroSection/>
        <About/>
        <Usluge/>
        <Galerija/>

    </div>
  )
}

export default Home