import React from 'react'

import drvoseca from '../../Assets/drvoseca.jpg'
import okolina from '../../Assets/okolina.jpg'
import kosilica from '../../Assets/kosilica.jpg'
import select from '../../Assets/gardening.jpg'
function Usluge() {
  return (
    <div id='usluge' className='relative'>
        <div className=''>
          <img className='w-screen h-[600px] object-cover ' src={select} alt="bg" />
        </div>

        <div className='absolute top-0 bottom-0 left-0 right-0 hidden sm:flex items-center flex-col justify-center bg-[rgba(0,0,0,0.80)]'>
            <p className='uppercase text-[#FA4219] font-miedum text-[20px]'>Naše Usluge</p>
            <p className='text-[32px] uppercase text-[#fff] font-bold'>Sta Pružamo?</p>
            <div className='flex gap-8 mt-8'>
                <div className='bg-[#fff] w-[400px]  h-[350px] items-start justify-start flex flex-col rounded-2xl text-[#000] font-bold text-[20px] relative'>
                    <p className='absolute bottom-0 z-10 text-start p-2 bg-[#fff] rounded-tr-xl rounded-bl-2xl'>Obaranje Stabala</p>
                    <img  className='rounded-2xl w-18 w-full h-full object-cover absolute top-0 right-0 left-0 bottom-0' src={drvoseca} alt="drvo" />
                </div>

                <div className='bg-[#fff] w-[400px]  h-[350px] items-start justify-start flex flex-col rounded-2xl text-[#000] font-bold text-[20px] relative'>
                    <p className='absolute bottom-0 z-10 text-start p-2 bg-[#fff] rounded-tr-xl rounded-bl-2xl'>Uredjenje Dvorišta</p>
                    <img  className='rounded-2xl w-18 w-full h-full object-cover absolute top-0 right-0 left-0 bottom-0' src={kosilica} alt="drvo" />
                </div>

                <div className='bg-[#fff] w-[400px]  h-[350px] items-start justify-start flex flex-col rounded-2xl text-[#000] font-bold text-[20px] relative'>
                    <p className='absolute bottom-0 z-10 text-start p-2 bg-[#fff] rounded-tr-xl rounded-bl-2xl'>Uredjenje Okoline</p>
                    <img  className='rounded-2xl w-18 w-full h-full object-cover absolute top-0 right-0 left-0 bottom-0' src={okolina} alt="drvo" />
                </div>

              


            </div>
        </div>

        <div className='flex flex-col sm:hidden absolute top-0 right-0 left-0 bottom-0 justify-center items-center gap-4 py-8 bg-[rgba(0,0,0,0.65)]'>

                <div className='flex flex-col justify-center items-center'>
                      <p className='uppercase text-[#FA4219] font-miedum text-[20px]'>Nase Usluge</p>
                     <p className='text-[32px] uppercase text-[#fff] font-bold'>Sta Pruzamo?</p>
                </div>
                <div className='bg-[#fff] w-[250px]  h-[250px] items-start justify-start flex flex-col rounded-2xl text-[#000] font-bold text-[16px] relative'>
                    <p className='absolute bottom-0 z-10 text-start p-2 bg-[#fff] rounded-tr-xl rounded-bl-2xl'>Obaranje Stabala</p>
                    <img  className='rounded-2xl w-18 w-full h-full object-cover absolute top-0 right-0 left-0 bottom-0' src={drvoseca} alt="drvo" />
                </div>

                <div className='bg-[#fff] w-[250px]  h-[250px] items-start justify-start flex flex-col rounded-2xl text-[#000] font-bold text-[16px] relative'>
                    <p className='absolute bottom-0 z-10 text-start p-2 bg-[#fff] rounded-tr-xl rounded-bl-2xl'>Uredjenje Dvorista</p>
                    <img  className='rounded-2xl w-18 w-full h-full object-cover absolute top-0 right-0 left-0 bottom-0' src={kosilica} alt="drvo" />
                </div>

                <div className='bg-[#fff] w-[250px]  h-[250px] items-start justify-start flex flex-col rounded-2xl text-[#000] font-bold text-[16px] relative'>
                    <p className='absolute bottom-0 z-10 text-start p-2 bg-[#fff] rounded-tr-xl rounded-bl-2xl'>Uredjenje Okoline</p>
                    <img  className='rounded-2xl w-18 w-full h-full object-cover absolute top-0 right-0 left-0 bottom-0' src={okolina} alt="drvo" />
                </div>
        </div>
   
    </div>
  )
}

export default Usluge